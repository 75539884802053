<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-20">
      <span class="a-fs-16 a-fw-700">在线卡管理</span>
      <div class="a-flex-rcc">
        <le-export-btn :page-param="pageParam"></le-export-btn>
      </div>
    </div>
    <el-card class="el-main">
      <le-search-form @reset="handlerRest" @search="handlerSearch">
        <le-input
          v-model="pageParam.params.cardNo"
          type="number"
          label="卡号"
          placeholder="请输入卡号"
        />
      </le-search-form>
      <le-pagview v-if="pageParam" :page-param="pageParam" @setData="setTableData">
        <el-table :data="tableData" :highlight-current-row="true" style="width: 100%">
          <el-table-column prop="cardNo" label="卡号"></el-table-column>
          <el-table-column label="当前余额">
            <template slot-scope="{ row }">
              <span v-if="row.cardBalance < 0">
                -&yen;{{ util.numFormat(Math.abs(row.cardBalance)) }}
              </span>
              <span v-else>&yen;{{ util.numFormat(row.cardBalance) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="默认充电时长">
            <template slot-scope="{ row }">
              <span>{{ (Number(row.maxChargingMinutes) / 60).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="充电次数">
            <template slot-scope="{ row }">
              <span>{{ row.chargingNum }}次</span>
            </template>
          </el-table-column>
          <el-table-column label="用户名/手机号">
            <template slot-scope="{ row }">
              <span class="a-c-blue a-cursor-p" @click="goUserDetail(row.userId)">
                {{ row.nickName || "无" }}/{{ row.userMobile || "无" }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="操作" fixed="right">
            <template slot-scope="{ row }">
              <div class="a-w-100 a-flex-rcc">
                <el-tooltip class="item" effect="dark" content="解绑" placement="top">
                  <div @click="handlerUnbindCard(row.userCardId)">
                    <img src="../../assets/icon/userMGT-unbind.png" class="a-wh-16" />
                  </div>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
import util from "../../utils/util";

export default {
  name: "ChargeCardOnLine",

  data() {
    return {
      util: util,
      tableData: [],
      pageParam: null,
      fileUrl: "",
    };
  },
  created() {
    this.pageParam = {
      url: this.$Config.apiUrl.onLineCardList,
      method: "post",
      params: {
        exportFlag: false,
        cardNo: "",
        startTime: "",
        endTime: "",
      },
      freshCtrl: 1,
    };
  },
  methods: {
    exportfile() {
      if (!this.fileUrl) {
        this.$message.warning("暂无可导出文件");
        return;
      }
      this.$confirm("是否确认导出文件？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Utils.downloadUrlFile(this.fileUrl, "在线充电卡列表");
      });
    },
    setTableData(data, fileUrl) {
      this.tableData = data;
      this.fileUrl = fileUrl;
    },
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    handlerRest() {
      this.pageParam.params = {
        exportFlag: false,
        cardNo: "",
      };
      this.dialogAddCard = false;
    },
    goUserDetail(id) {
      if (!id) {
        this.$message.error("此卡没有关联用户！");
        return false;
      }
      this.$router.push({
        path: "/userMGT/userMGT-detail",
        query: { id: id },
      });
    },
    handlerUnbindCard(id) {
      this.$confirm("是否确认解绑该卡？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.unbindUserCard,
            params: {
              userCardId: id,
            },
          })
          .then((res) => {
            if (res.result.code == 0 && res.result.data) {
              this.$notify({
                title: "解绑成功",
                type: "success",
              });
              this.pageParam.freshCtrl++;
            } else {
              this.$message.error(res.result.message);
            }
          })
          .catch((error) => {
            this.$message.error(error);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
  justify-content: flex-start;
  width: 72px !important;
}

.addDialog /deep/ .el-dialog__header {
  font-size: 16px;
  color: #303133;
  font-weight: bold;
  padding: 16px 24px;
}

.addDialog /deep/ .el-dialog__body {
  border-top: 1px solid #ebf0f5;
  border-bottom: 1px solid #ebf0f5;
  height: 136px;
  padding-left: 24px;
  padding-right: 24px;
}

.addDialog /deep/ .el-dialog__footer {
  padding-left: 24px;
  padding-right: 24px;
}

.addDialog /deep/ .el-form-item__label {
  text-align: left;
}
</style>
